<div
  *ngIf="showBanner"
  [@bottomOut]="transitionBanner"
  (@bottomOut.done)="acceptAllAnimationDone($event)"
  class="cookies-banner"
>
  <!-- <div class="cookie-text" [ishServerHtml]="'cookies.banner.text' | translate"></div> -->

  <!-- <button type="button" class="btn btn-primary float-right" (click)="acceptAll()" data-testing-id="acceptAllButton">
    {{ 'cookies.banner.accept_all' | translate }}
  </button> -->

  <!-- <button type="button" class="btn btn-outline-primary float-right" routerLink="/cookies">
    {{ 'cookies.banner.set_preferences' | translate }}
  </button> -->
</div>
