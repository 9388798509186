import { overrides } from './environment.development';
import { ENVIRONMENT_DEFAULTS, Environment } from './environment.model';

export const environment: Environment = {
  ...ENVIRONMENT_DEFAULTS,

  icmChannel: '',
  themeColor: '#688dc3',
  mainNavigationMaxSubCategoriesDepth: 4,
  gtmToken: '',

  ...overrides,
};
