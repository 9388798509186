import {
  ChangeDetectionStrategy, Component, createNgModule, OnInit, ViewChild, ViewContainerRef,
  Injector,
  ComponentRef,
  Input, OnChanges, 
  
} from '@angular/core';



import type { SearchBoxComponent as OriginalComponent } from '../../../shared/components/search/search-box/search-box.component';

@Component({
  selector: 'ish-lazy-search-box',
  templateUrl: './lazy-search-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazySearchBoxComponent implements OnInit , OnChanges {
  /*
   * WARNING!
   *
   * This file was automatically generated!
   * It should be updated using:
   *
   * ng g lazy-component shared/components/search/search-box/search-box.component.ts
   *
   */

  @ViewChild('anchor', { read: ViewContainerRef, static: true }) anchor: ViewContainerRef;


  @Input() configuration: OriginalComponent['configuration'];


  private component: ComponentRef<OriginalComponent>;

  constructor(
    
    private injector: Injector
  ) {}

  async ngOnInit() {
    

      await this.renderComponent();

    
  }

  private async renderComponent() {
    const module = await import(`../../../shared/shared.module`).then(m => m.SharedModule);

    const { SearchBoxComponent: originalComponent } = await import('../../../shared/components/search/search-box/search-box.component');

    const ngModuleRef = createNgModule(module, this.injector);

    this.component = this.anchor.createComponent(originalComponent, { ngModuleRef });
  
    this.ngOnChanges(
      
    );
  
    this.component.changeDetectorRef.markForCheck();
  }


  ngOnChanges() {
    if (this.component) {
      
        this.component.instance.configuration = this.configuration;
      

      
    }
  }

}
