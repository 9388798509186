<ng-container *ngIf="logoutOnly; else loginStatus">
  <ng-container *ngIf="user$ | async as user; else notLoggedIn">
    <a routerLink="/logout" data-testing-id="link-logout" aria-label="logout">
      <fa-icon [icon]="['fas', 'user']" class="header-icon"></fa-icon>
      <span *ngIf="view !== 'small'"> {{ 'account.logout.link' | translate }} </span>
    </a>
  </ng-container>
  <ng-template #notLoggedIn> &nbsp; </ng-template>
</ng-container>

<ng-template #loginStatus>
  <!-- logged in -->

  <ng-container *ngIf="user$ | async as user; else notLoggedIn">
    <a routerLink="/account" class="my-account-link-logged" data-testing-id="link-myaccount" aria-label="account">
      {{ user.firstName[0] + user.lastName[0] }}
    </a>
  </ng-container>

  <!-- not logged in -->

  <ng-template #notLoggedIn>
    <a
      routerLink="/login"
      [queryParams]="{ returnUrl: '/account' }"
      class="my-account-link my-account-login"
      rel="nofollow"
      aria-label="login"
    >
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19 21L5 21C4.44772 21 4 20.5523 4 20L4 19C4 17 7 15 12 15C17.1461 15 20 17 20 19V20C20 20.5523 19.5523 21 19 21Z"
          stroke="#004A93"
          fill="#fff"
          stroke-width="2"
        />

        <circle cx="12" cy="7" r="4" stroke="#004A93" stroke-width="2" fill="#fff" />
      </svg>
    </a>

    <a
      routerLink="/register"
      [queryParams]="{ returnUrl: '/account' }"
      class="my-account-link my-account-register"
      rel="nofollow"
      aria-label="register"
    >
    </a>
  </ng-template>
</ng-template>
