<!--NEW MOBILE MENU OPEN-->
<div class="global-nav-container-new mobile-menu d-block d-md-none">
  <nav class="global-nav navbar-expand-md" aria-label="main">
    <div class="main-navigation">
      <div class="mini-header row">
        <div class="col-6 logo-wrap">
          <a
            *ngIf="showDesktopLogoLink"
            rel="home"
            routerLink="/home"
            class="logo"
            [attr.aria-label]="'common.home.link' | translate"
            data-testing-id="header-home-link-desktop"
            ><svg width="81" height="31" viewBox="0 0 81 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.42871 0.03125H0V29.4545H6.05671V9.75602H6.13938L18.3792 29.4545H24.8492V0.03125H18.7906V19.7701H18.7084L6.42871 0.03125Z"
                fill="#004A93"
              />
              <path d="M34.3213 4.85278H28.4693V0.03125H34.3213V4.85278Z" fill="#004A93" />
              <path d="M34.3213 29.4545H28.4693V8.1498H34.3213V29.4545Z" fill="#004A93" />
              <path
                d="M51.7267 15.6493C51.3561 13.2592 49.8306 11.9822 47.3997 11.9822C43.6499 11.9822 42.4133 15.7733 42.4133 18.9055C42.4133 21.955 43.6086 25.6221 47.2766 25.6221C49.996 25.6221 51.5618 23.8914 51.9328 21.2956H57.5781C56.8361 26.9404 52.9219 30.0312 47.317 30.0312C40.8883 30.0312 36.5618 25.499 36.5618 19.1107C36.5618 12.4768 40.5177 7.57258 47.441 7.57258C52.4687 7.57258 57.0841 10.2107 57.4541 15.6493H51.7267Z"
                fill="#004A93"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M70.2919 7.57258C77.5449 7.57258 81.0476 13.6721 80.6352 20.2243H65.2647C65.429 23.9327 67.2424 25.6221 70.4986 25.6221C72.8473 25.6221 74.7424 24.1802 75.1139 22.8614H80.2647C78.6166 27.8877 75.1139 30.0312 70.2919 30.0312C63.5748 30.0312 59.4132 25.4164 59.4132 18.8228C59.4132 12.4359 63.8219 7.57258 70.2919 7.57258ZM70.127 11.9822C66.4186 11.9822 65.3464 14.8669 65.2647 16.5154H74.7832C74.2478 13.5481 72.9704 11.9822 70.127 11.9822Z"
                fill="#004A93"
              />
            </svg>
          </a>
          <a
            *ngIf="showMobileLogoLink"
            rel="home"
            routerLink="/home"
            class="mobile-logo"
            [attr.aria-label]="'common.home.link' | translate"
            data-testing-id="header-home-link-mobile"
            ><svg width="81" height="31" viewBox="0 0 81 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.42871 0.03125H0V29.4545H6.05671V9.75602H6.13938L18.3792 29.4545H24.8492V0.03125H18.7906V19.7701H18.7084L6.42871 0.03125Z"
                fill="#004A93"
              />
              <path d="M34.3213 4.85278H28.4693V0.03125H34.3213V4.85278Z" fill="#004A93" />
              <path d="M34.3213 29.4545H28.4693V8.1498H34.3213V29.4545Z" fill="#004A93" />
              <path
                d="M51.7267 15.6493C51.3561 13.2592 49.8306 11.9822 47.3997 11.9822C43.6499 11.9822 42.4133 15.7733 42.4133 18.9055C42.4133 21.955 43.6086 25.6221 47.2766 25.6221C49.996 25.6221 51.5618 23.8914 51.9328 21.2956H57.5781C56.8361 26.9404 52.9219 30.0312 47.317 30.0312C40.8883 30.0312 36.5618 25.499 36.5618 19.1107C36.5618 12.4768 40.5177 7.57258 47.441 7.57258C52.4687 7.57258 57.0841 10.2107 57.4541 15.6493H51.7267Z"
                fill="#004A93"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M70.2919 7.57258C77.5449 7.57258 81.0476 13.6721 80.6352 20.2243H65.2647C65.429 23.9327 67.2424 25.6221 70.4986 25.6221C72.8473 25.6221 74.7424 24.1802 75.1139 22.8614H80.2647C78.6166 27.8877 75.1139 30.0312 70.2919 30.0312C63.5748 30.0312 59.4132 25.4164 59.4132 18.8228C59.4132 12.4359 63.8219 7.57258 70.2919 7.57258ZM70.127 11.9822C66.4186 11.9822 65.3464 14.8669 65.2647 16.5154H74.7832C74.2478 13.5481 72.9704 11.9822 70.127 11.9822Z"
                fill="#004A93"
              />
            </svg>
          </a>
        </div>
        <div class="col-6 menu-wrap">
          <div class="icons-mobile" [ngbCollapse]="!showNavBar">
            <ng-container *ngIf="user$ | async as user; else showThis">
              <a
                routerLink="/account"
                class="my-account-link-logged"
                data-testing-id="link-myaccount"
                aria-label="account"
              >
                {{ user.firstName[0] + user.lastName[0] }}
              </a>
            </ng-container>
            <ng-template #showThis>
              <a href="/login?returnUrl=%2Faccount" class="d-flex flex-row">
                <svg
                  viewBox="0 0 24 24"
                  style="width: 1.5rem; margin-right: 0.75rem"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 21L5 21C4.44772 21 4 20.5523 4 20L4 19C4 17 7 15 12 15C17.1461 15 20 17 20 19V20C20 20.5523 19.5523 21 19 21Z"
                    stroke="#004A93"
                    fill="#fff"
                    stroke-width="2"
                  ></path>
                  <circle cx="12" cy="7" r="4" stroke="#004A93" stroke-width="2" fill="#fff"></circle>
                </svg>
                Login
              </a>
            </ng-template>
          </div>
          <div class="icons-mobile" [ngbCollapse]="showNavBar">
            <div class="icons-mobile d-flex">
              <ish-mini-basket></ish-mini-basket>

              <svg
                (click)="openSearch()"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style="width: 1.5rem"
                class="ng-star-inserted"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.4097 15.0597C18.3705 13.4873 18.7809 11.5584 18.4233 9.58457C17.6531 5.33313 13.6135 2.45653 9.41741 3.17122C5.22135 3.88591 2.43487 7.92525 3.20512 12.1767C3.97538 16.4281 8.01486 19.3048 12.2109 18.5901C13.6758 18.3406 14.969 17.684 15.9978 16.7575L19.3793 19.4856C19.8593 19.8739 20.5539 19.7982 20.931 19.3189C21.1353 19.0597 21.2041 18.7339 21.149 18.4302C21.102 18.1704 20.9632 17.9261 20.7427 17.7481L17.4097 15.0597ZM11.816 16.4016C8.81706 16.9124 5.93119 14.8572 5.38068 11.8185C4.83017 8.77982 6.82087 5.89395 9.81985 5.38313C12.8188 4.87231 15.7047 6.92756 16.2552 9.96622C16.8057 13.0049 14.815 15.8908 11.816 16.4016Z"
                  fill="#004A93"
                ></path>
              </svg>
            </div>
          </div>
          <button class="navbar-toggler" type="button" (click)="toggle('navbar')">
            <span class="sr-only">{{ 'common.button.navbarCollapsed.text' | translate }}</span>
            <div class="hamburger-menu" [ngClass]="{ open: showNavBar }">
              <span class="first-line-hamburger"></span> <span class="second-line-hamburger"></span>
              <span class="third-line-hamburger"></span>
            </div>
          </button>
        </div>
      </div>
      <ish-header-navigation class="header-navigation-mobile" [ngbCollapse]="!showNavBar"></ish-header-navigation>
    </div>
  </nav>
  <ng-container *ngIf="openSearchValue">
    <ish-lazy-search-box
      data-testing-id="search-box-desktop"
      [configuration]="{
        placeholder: 'search.searchbox.instructional_text' | translate,
        autoSuggest: false,
        maxAutoSuggests: 10,
        icon: 'magnifying-glass',
        showLastSearchTerm: false
      }"
    ></ish-lazy-search-box>
  </ng-container>
</div>

<!-- Header -->
<div class="header d-none d-md-flex" [ngClass]="deviceType">
  <!-- Mid Header -->
  <div class="mid-header row d-flex justify-content-between align-items-center">
    <div class="logo-col">
      <div class="logo-wrapper">
        <a
          *ngIf="showDesktopLogoLink"
          rel="home"
          routerLink="/home"
          class="logo"
          [attr.aria-label]="'common.home.link' | translate"
          data-testing-id="header-home-link-desktop"
        ></a>
      </div>
    </div>

    <!-- <div class="col-md-11 col-8"> -->
    <div class="nav-col">
      <!-- Content Up -->
      <div class="content-col-header-up d-none d-md-block">
        <div class="title">
          <a [href]="getLocaleBasedNiceHref()">{{ 'common.b2b.link.title' | translate }}</a>
          <a [href]="getLocaleBasedB2BHref()" *ngIf="'preferences.ChannelPreferences.isB2cSite' | ishServerSetting">{{
            'common.b2b.link.text' | translate
          }}</a>
        </div>
      </div>
      <!--Content Down-->
      <div class="content-col-header-down">
        <!--Menu-->
        <nav class="global-nav navbar-expand-md d-none d-md-flex" [ngbCollapse]="!showNavBar" aria-label="main">
          <div class="main-navigation d-none d-md-flex">
            <ish-header-navigation></ish-header-navigation>
            <ish-user-information-mobile></ish-user-information-mobile>
          </div>
        </nav>
        <!--Support Menu-->
        <div class="dx-section d-none d-md-flex icons-mobile">
          <ish-mini-basket
            data-testing-id="mini-basket-mobile"
            view="small"
            [ngClass]="isSticky ? 'd-lg-none' : 'd-md-none'"
          ></ish-mini-basket>

          <ish-mini-basket
            data-testing-id="mini-basket-desktop"
            view="auto"
            class="d-none"
            [ngClass]="isSticky ? 'd-lg-block' : 'd-md-block'"
          ></ish-mini-basket>

          <button
            class="btn-search btn"
            type="submit"
            name="search"
            [title]="'search.searchbox.button.title' | translate"
            (click)="openSearchPage()"
          >
            <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
          </button>

          <ish-login-status data-testing-id="user-status-desktop d-none d-md-block"></ish-login-status>
        </div>
        <!--Menu Mobile-->

        <!-- Icons Mobile-->
        <div class="col icons-mobile d-none">
          <ish-mini-basket data-testing-id="mini-basket-mobile" view="small"></ish-mini-basket>

          <ish-lazy-search-box
            data-testing-id="search-box-desktop"
            [configuration]="{
              placeholder: 'search.searchbox.instructional_text' | translate,
              autoSuggest: false,
              maxAutoSuggests: 10,
              icon: 'magnifying-glass',
              showLastSearchTerm: false
            }"
          ></ish-lazy-search-box>
        </div>
      </div>
    </div>
  </div>
</div>
