<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<ng-container *ngIf="deviceType$ | async as deviceType">
  <ul class="navbar-nav main-navigation-list">
    <li
      *ngFor="let category of categories$ | async"
      #subMenu
      class="dropdown"
      [ngClass]="{ open: isOpened(category.uniqueId) }"
      (mouseenter)="subMenuShow(subMenu)"
      (mouseleave)="subMenuHide(subMenu)"
      (click)="subMenuHide(subMenu)"
    >
      <ng-container *ngIf="deviceType !== 'mobile'; else mobileNavigation">
        <a
          [routerLink]="category.url"
          [attr.data-testing-id]="category.uniqueId + '-link'"
          [ngStyle]="{ width: !(0 < mainNavigationMaxSubCategoriesDepth && category.hasChildren) ? '100%' : '' }"
          class="main-navigation-link"
        >
          {{ category.name }}
        </a>
      </ng-container>

      <ng-template #mobileNavigation>
        <a
          [attr.data-testing-id]="category.uniqueId + '-link'"
          [ngStyle]="{ width: !(0 < mainNavigationMaxSubCategoriesDepth && category.hasChildren) ? '100%' : '' }"
          class="main-navigation-link"
          (click)="toggleOpen(category.uniqueId)"
        >
          <h4 class="nav-mobile-menu-btn" *ngIf="isOpened(category.uniqueId)">
            <fa-icon [icon]="['fas', 'angle-left']"></fa-icon>
            MENU
          </h4>
          {{ category.name }}
        </a>
      </ng-template>

      <ng-container *ngIf="0 < mainNavigationMaxSubCategoriesDepth && category.hasChildren">
        <a
          [routerLink]="[]"
          class="dropdown-toggle"
          (click)="toggleOpen(category.uniqueId)"
          [attr.aria-label]="isOpened(category.uniqueId) ? 'collapse ' + category.name : 'expand ' + category.name"
        >
          <fa-icon *ngIf="isOpened(category.uniqueId); else closed" [icon]="['fas', 'angle-left']"></fa-icon>
          <ng-template #closed> <fa-icon [icon]="['fas', 'angle-right']"></fa-icon> </ng-template>
        </a>
      </ng-container>

      <div class="d-block d-md-none">
        <ish-sub-category-navigation
          [categoryUniqueId]="category.uniqueId"
          [subCategoriesDepth]="1"
          [view]="view"
        ></ish-sub-category-navigation>
      </div>

      <div class="d-none d-md-block">
        <ish-custom-megamenu
          [category]="category"
          [view]="view"
          [mainNavigationMaxSubCategoriesDepth]="mainNavigationMaxSubCategoriesDepth"
        ></ish-custom-megamenu>
      </div>
    </li>

    <ish-lazy-content-include
      includeId="include.header.navigation.pagelet2-Include"
      role="listitem"
    ></ish-lazy-content-include>
  </ul>
  <ul class="d-md-none sites-b2b-b2c">
    <li>
      <a href="https://niceforyou.com">{{ 'common.b2b.link.title' | translate }}</a>
    </li>
    <li *ishFeature="'wishlists'">
      <a href="https://partnershop.niceforyou.com/it_it/">{{ 'common.b2b.link.text' | translate }}</a>
    </li>
  </ul>
</ng-container>
