<div
  class="quick-cart-link"
  [ngClass]="{ 'd-md-block': view !== 'small', 'mini-cart-active': !isCollapsed }"
  ishClickOutside
  (isClickedOutside)="collapse()"
>
  <a [routerLink]="[]" (click)="toggleCollapse()" class="cart-btn">
    <span class="number-items">{{ itemCount$ | async }}</span>
    <span class="cart-icon">
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        style="padding: 0; margin: 0"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 4C6 3.15414 6.21079 2.70343 6.45711 2.45711C6.70343 2.21079 7.15414 2 8 2C8.84586 2 9.29657 2.21079 9.54289 2.45711C9.78921 2.70343 10 3.15414 10 4V5L6 5V4ZM4 7V9C4 9.55229 4.44772 10 5 10C5.55228 10 6 9.55229 6 9L6 7L10 7V9C10 9.55229 10.4477 10 11 10C11.5523 10 12 9.55229 12 9V7L14 7L14 18L2 18L2 7L4 7ZM4 5L4 4C4 2.84586 4.28921 1.79657 5.04289 1.04289C5.79657 0.289213 6.84586 0 8 0C9.15414 0 10.2034 0.289213 10.9571 1.04289C11.7108 1.79657 12 2.84586 12 4V5L14 5C15.1046 5 16 5.89543 16 7L16 18C16 19.1046 15.1046 20 14 20L2 20C0.895431 20 0 19.1046 0 18L0 7C0 5.89543 0.895431 5 2 5L4 5Z"
        />
      </svg>
    </span>
  </a>

  <div *ngIf="!isCollapsed" class="mini-cart">
    <ish-lazy-mini-basket-content></ish-lazy-mini-basket-content>
  </div>
</div>

<a routerLink="/basket" *ngIf="view === 'small'" class="item-count-container position-relative">
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      stroke-width="2"
      d="M6 4C6 3.15414 6.21079 2.70343 6.45711 2.45711C6.70343 2.21079
      7.15414 2 8 2C8.84586 2 9.29657 2.21079 9.54289 2.45711C9.78921 2.70343 10 3.15414 10 4V5L6 5V4ZM4 7V9C4 9.55229
      4.44772 10 5 10C5.55228 10 6 9.55229 6 9L6 7L10 7V9C10 9.55229 10.4477 10 11 10C11.5523 10 12 9.55229 12 9V7L14
      7L14 18L2 18L2 7L4 7ZM4 5L4 4C4 2.84586 4.28921 1.79657 5.04289 1.04289C5.79657 0.289213 6.84586 0 8 0C9.15414 0
      10.2034 0.289213 10.9571 1.04289C11.7108 1.79657 12 2.84586 12 4V5L14 5C15.1046 5 16 5.89543 16 7L16 18C16 19.1046
      15.1046 20 14 20L2 20C0.895431 20 0 19.1046 0 18L0 7C0 5.89543 0.895431 5 2 5L4 5Z"
      fill="#004A93"
    />
  </svg>
  <span class="badge badge-pill" [ngClass]="basketAnimation$ | async">{{ itemCount$ | async }}</span>
</a>

<!-- dummy element tracking basket loading for cypress tests -->
<span *ngIf="basketLoading$ | async" class="loading" style="display: none"></span>
