<ng-container *ngIf="(isLoggedIn$ | async) !== true">
  <div class="top-header container-fluid" [class.hide-on-scroll]="isMobileAndScrolled">
    <p *ngIf="!('preferences.ChannelPreferences.isB2cSite' | ishServerSetting); else b2cHeader">
      {{ 'common.b2b.login.text' | translate }}
      <a routerLink="/login">{{ 'common.login.text' | translate }}</a>
    </p>
    <ng-template #b2cHeader>
      <p>
        {{ 'common.b2c.login.text' | translate }}
        <a routerLink="/login">{{ 'common.login.text' | translate }}</a>
      </p>
    </ng-template>
  </div>
</ng-container>

<ng-container [ngSwitch]="headerType$ | async">
  <!-- <ish-header-error *ngSwitchCase="'error'"></ish-header-error>
  <ish-header-simple *ngSwitchCase="'simple'"></ish-header-simple>
  <ish-header-checkout *ngSwitchCase="'checkout'"></ish-header-checkout> -->

  <ng-container *ngSwitchDefault>
    <ish-header-default
      [isSticky]="isSticky$ | async"
      [deviceType]="deviceType$ | async"
      [reset]="reset$ | async"
    ></ish-header-default>
  </ng-container>
</ng-container>

<ish-back-to-top></ish-back-to-top>
