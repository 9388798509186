import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AccountFacade } from 'ish-core/facades/account.facade';
import { AppFacade } from 'ish-core/facades/app.facade.nice';
import { DeviceType } from 'ish-core/models/viewtype/viewtype.types';

@Component({
  selector: 'ish-header',
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  headerType$: Observable<string>;
  deviceType$: Observable<DeviceType>;
  isSticky$: Observable<boolean>;
  reset$: Observable<Event>;
  isLoggedIn$: Observable<boolean>;
  isMobileAndScrolled = false;

  constructor(private appFacade: AppFacade, private accountFacade: AccountFacade, private router: Router) {}

  ngOnInit() {
    this.headerType$ = this.appFacade.headerType$;
    this.deviceType$ = this.appFacade.deviceType$;
    this.isSticky$ = this.appFacade.stickyHeader$;
    this.reset$ = this.router.events.pipe(filter(event => event instanceof NavigationStart));
    this.isLoggedIn$ = this.accountFacade.isLoggedIn$;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    // Check if the scroll position is greater than 50 pixels and the device is mobile
    this.isMobileAndScrolled = window.scrollY > 50 && this.appFacade.isMobileDevice();
  }
}
