import {
  ChangeDetectionStrategy, Component, createNgModule, OnInit, ViewChild, ViewContainerRef,
  Injector,
  ComponentRef,
  Input, OnChanges, OnDestroy
} from '@angular/core';


import { FeatureToggleService } from 'ish-core/feature-toggle.module';
import { takeUntil } from 'rxjs/operators';


import type { WishlistsLinkComponent as OriginalComponent } from '../../shared/wishlists-link/wishlists-link.component';
import { Subject } from "rxjs";

@Component({
  selector: 'ish-lazy-wishlists-link',
  templateUrl: './lazy-wishlists-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyWishlistsLinkComponent implements OnInit, OnChanges, OnDestroy {
  /*
   * WARNING!
   *
   * This file was automatically generated!
   * It should be updated using:
   *
   * ng g lazy-component extensions/wishlists/shared/wishlists-link/wishlists-link.component.ts
   *
   */

  @ViewChild('anchor', { read: ViewContainerRef, static: true }) anchor: ViewContainerRef;


  @Input() view: OriginalComponent['view'];
  private destroy$ = new Subject<void>();
  private component: ComponentRef<OriginalComponent>;

  constructor(
    private featureToggleService: FeatureToggleService,
    private injector: Injector
  ) { }

  ngOnInit() {
    this.featureToggleService.enabled$('wishlists').pipe(takeUntil(this.destroy$)).subscribe(async enabled => {
      if (enabled) {


        await this.renderComponent();


      } else {
        this.anchor.clear();
      }
    })

  }

  private async renderComponent() {
    const module = await import(`../../wishlists.module`).then(m => m.WishlistsModule);

    const { WishlistsLinkComponent: originalComponent } = await import('../../shared/wishlists-link/wishlists-link.component');

    const ngModuleRef = createNgModule(module, this.injector);

    this.component = this.anchor.createComponent(originalComponent, { ngModuleRef });

    this.ngOnChanges(

    );

    this.component.changeDetectorRef.markForCheck();
  }


  ngOnChanges() {
    if (this.component) {

      this.component.instance.view = this.view;



    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
