<div class="mega-menu row">
  <div class="menu-category-card col-3">
    <h4>{{ category.name }}</h4>

    <p *ngIf="category$ | async as category">
      {{ category.description }}
    </p>

    <a
      [routerLink]="category.url"
      [attr.data-testing-id]="category.uniqueId + '-link'"
      [ngStyle]="{ width: !(0 < mainNavigationMaxSubCategoriesDepth && category.hasChildren) ? '100%' : '' }"
      class="btn btn-primary"
    >
      {{ 'header.megamenu.discover' | translate }}
    </a>
  </div>

  <div class="menu-subcategory-nav col">
    <ish-sub-category-navigation
      [categoryUniqueId]="category.uniqueId"
      [subCategoriesDepth]="1"
      [view]="view"
    ></ish-sub-category-navigation>
  </div>

  <div class="menu-category-image col">
    <div *ngIf="category$ | async as category">
      <img *ngIf="category.images as arrar" [src]="arrar[0].effectiveUrl" alt="" />
    </div>
  </div>
</div>
