import {
  ChangeDetectionStrategy, Component, createNgModule, OnInit, ViewChild, ViewContainerRef,
  Injector,
  ComponentRef,
  
  
} from '@angular/core';



import type { MiniBasketContentComponent as OriginalComponent } from '../../../shared/components/basket/mini-basket-content/mini-basket-content.component';

@Component({
  selector: 'ish-lazy-mini-basket-content',
  templateUrl: './lazy-mini-basket-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyMiniBasketContentComponent implements OnInit {
  /*
   * WARNING!
   *
   * This file was automatically generated!
   * It should be updated using:
   *
   * ng g lazy-component shared/components/basket/mini-basket-content/mini-basket-content.component.ts
   *
   */

  @ViewChild('anchor', { read: ViewContainerRef, static: true }) anchor: ViewContainerRef;



  private component: ComponentRef<OriginalComponent>;

  constructor(
    
    private injector: Injector
  ) {}

  async ngOnInit() {
    

      await this.renderComponent();

    
  }

  private async renderComponent() {
    const module = await import(`../../../shared/shared.module`).then(m => m.SharedModule);

    const { MiniBasketContentComponent: originalComponent } = await import('../../../shared/components/basket/mini-basket-content/mini-basket-content.component');

    const ngModuleRef = createNgModule(module, this.injector);

    this.component = this.anchor.createComponent(originalComponent, { ngModuleRef });
  
    this.component.changeDetectorRef.markForCheck();
  }


}
