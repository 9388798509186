<ul
  *ngIf="navigationCategories$ | async as categories"
  class="category-level{{ subCategoriesDepth }}"
  [ngClass]="{ 'dropdown-menu': subCategoriesDepth === 1 }"
>
  <li
    *ngFor="let subcategory of categories"
    class="main-navigation-level{{ subCategoriesDepth }}-item"
    [ngClass]="{ open: isOpened(subcategory.uniqueId) }"
  >
    <div class="menu-voice-content">
      <a
        [routerLink]="subcategory.url"
        [ngStyle]="{
          width: !(subCategoriesDepth < mainNavigationMaxSubCategoriesDepth && subcategory.hasChildren) ? '100%' : ''
        }"
        >{{ subcategory.name }}</a
      >
      <ng-container *ngIf="subCategoriesDepth < mainNavigationMaxSubCategoriesDepth && subcategory.hasChildren">
        <a
          [routerLink]="[]"
          class="dropdown-toggle"
          [ngClass]="{ 'd-none': view === 'full' }"
          (click)="toggleOpen(subcategory.uniqueId)"
          [attr.aria-label]="
            isOpened(subcategory.uniqueId) ? 'collapse ' + subcategory.name : 'expand ' + subcategory.name
          "
        >
          <fa-icon *ngIf="isOpened(subcategory.uniqueId); else closed" [icon]="['fas', 'angle-left']"></fa-icon>
          <ng-template #closed>
            <svg width="1rem" height="1rem" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.228835 8.47303C-0.0699219 8.74689 -0.0761458 9.02074 0.210163 9.29461C0.496471 9.56847 0.776551 9.56847 1.05041 9.29461L4.93423 5.41079C5.05871 5.3112 5.12095 5.17427 5.12095 5C5.12095 4.82573 5.05871 4.6888 4.93423 4.58921L1.05041 0.705395C0.776551 0.431535 0.496471 0.431535 0.210163 0.705395C-0.0761458 0.979256 -0.0699219 1.25311 0.228835 1.52697L3.66452 5L0.228835 8.47303Z"
                fill="#004A93"
              />
            </svg>
          </ng-template>
        </a>
      </ng-container>
    </div>

    <ng-container *ngIf="subCategoriesDepth < mainNavigationMaxSubCategoriesDepth && subcategory.hasChildren">
      <ish-sub-category-navigation
        [categoryUniqueId]="subcategory.uniqueId"
        [subCategoriesDepth]="subCategoriesDepth + 1"
      ></ish-sub-category-navigation>
    </ng-container>
  </li>
</ul>
