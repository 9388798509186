import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ShoppingFacade } from 'ish-core/facades/shopping.facade';
import { CategoryView } from 'ish-core/models/category-view/category-view.model';
import { NavigationCategory } from 'ish-core/models/navigation-category/navigation-category.model';

@Component({
  selector: 'ish-custom-megamenu',
  templateUrl: './megamenu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MegamenuComponent implements OnInit {
  @Input() category: NavigationCategory;
  @Input() mainNavigationMaxSubCategoriesDepth: number;
  @Input() view: string;

  category$: Observable<CategoryView>;

  constructor(private shoppingFacade: ShoppingFacade) {}

  ngOnInit() {
    this.category$ = this.shoppingFacade.category$(this.category.uniqueId);
  }
}
