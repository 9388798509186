import {
  ChangeDetectionStrategy, Component, createNgModule, OnInit, ViewChild, ViewContainerRef,
  Injector,
  ComponentRef,
  
  
} from '@angular/core';



import type { ApprovalWidgetComponent as OriginalComponent } from '../../components/approval-widget/approval-widget.component';

@Component({
  selector: 'ish-lazy-approval-widget',
  templateUrl: './lazy-approval-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyApprovalWidgetComponent implements OnInit {
  /*
   * WARNING!
   *
   * This file was automatically generated!
   * It should be updated using:
   *
   * ng g lazy-component components/approval-widget/approval-widget.component.ts
   *
   */

  @ViewChild('anchor', { read: ViewContainerRef, static: true }) anchor: ViewContainerRef;



  private component: ComponentRef<OriginalComponent>;

  constructor(
    
    private injector: Injector
  ) {}

  async ngOnInit() {
    

      await this.renderComponent();

    
  }

  private async renderComponent() {
    const module = await import(`../../requisition-management.module`).then(m => m.RequisitionManagementModule);

    const { ApprovalWidgetComponent: originalComponent } = await import('../../components/approval-widget/approval-widget.component');

    const ngModuleRef = createNgModule(module, this.injector);

    this.component = this.anchor.createComponent(originalComponent, { ngModuleRef });
  
    this.component.changeDetectorRef.markForCheck();
  }


}
