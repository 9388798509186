import { Environment } from "./environment.model";

/* eslint-disable */

// running out of memory?
// NODE_OPTIONS=--max_old_space_size=8192

export const overrides: Partial<Environment> = {
  // icmBaseURL: 'http://localhost:8081'

  // defaultDeviceType: 'desktop',

  // features: ['compare'],
};
